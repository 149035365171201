*{
    margin: 0;
    padding: 0;
    text-transform:  capitalize;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.container{
    position: relative;
    min-height: 100vh;
    background: #334;
}

.container .video-container{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;
    padding: 10px;
}

.container .video-container .video{
    height: 250px;
    width: 350px;
    /*border: 5px solid #ffff;*/
    border-radius: 5px;
    /*box-shadow: 0 5px 15px rgba(0,0,0,.7);*/
    cursor: pointer;
}

.container .video-container .video iframe{
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: .2s linear;
}

.container .video-container .video:hover iframe{
    transform: scale(1.1);
}

.container .popup-video{
    position: fixed;
    top: 0;
    left:0;
    z-index: 100;
    background: rgba(0, 0, 0, .8);
    height: 100%;
    width: 100%;
  
}

.container .popup-video iframe{
    margin-top: 100px;
    align-items: center;
    justify-content: center;
    width: 750px;
    height: 564px;
    border-radius: 5px;
 /*   border: 3px solid #fff;*/
    object-fit:cover;

}

.container .popup-video span{
    position: absolute;
    top: 5px;
    right: 20px;
    font-size: 50px;
    color:#ffff;
    font-weight: bolder;
    z-index: 100;;
    cursor: pointer;

}
@media (max-width:768px) {
    .container .popup-video iframe{
        width: 95% ;
    }
}